import Image from 'next/image';
import styled from 'styled-components';
import colors from '@pickles/shared/utils/colors';
import sizes from '../../util/sizes';
import { size } from 'lodash';

export const BannerContainer = styled.div`
  max-width: ${sizes.XL};
  margin: 0 auto;
  width: 100%;
  margin-top: 12px;
  font-family: 'BasierCircle';
  @media (max-width: ${sizes.S}) {
    // display: none;
    margin-bottom: 0;
    margin-top: 0;
  }
`;

interface IBannerContainer {
  backgroundImage?: string;
  multipleBanners: boolean;
}

export const BannerBackground = styled.div<IBannerContainer>`
  background: ${(props) =>
    props.backgroundImage
      ? `url(${props.backgroundImage})`
      : `linear-gradient(226.83deg, ${colors.blumine_main_blue} 25.8%, ${colors.blue_zodiak} 123.58%)`};
  background-size: cover;
  display: flex;
  flex-direction: row;
  border-radius: 24px;
  justify-content: center;
  margin-bottom: 48px;

  &:before {
    content: '';
    background: ${(props) =>
      !props.backgroundImage ? "url('/icons/circles.svg') no-repeat;" : ''};
    background-size: cover;
    opacity: 0.7;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: 87px;
    margin-right: 87px;
    z-index: 0;
  }

  @media (max-width: ${sizes.XXL}) {
    margin-left: 85px;
    margin-right: 85px;
  }

  @media (max-width: ${sizes.L}) {
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: ${(props) => (props.multipleBanners ? '0px' : '28px')};
  }

  @media (max-width: ${sizes.M}) {
    flex-direction: column;
    padding: 0;
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 0px;
  }

  @media (max-width: ${sizes.S}) {
    flex-direction: column;
    background-position: center;
    margin-left: 0;
    margin-right: 0;
    border-radius: 0px;
    padding-bottom: 0;
  }
`;

interface IBannerNav {
  location: 'left' | 'right';
}

export const BannerNav = styled.button<IBannerNav>`
  border: 0;
  padding: 20px 0px;
  background: transparent;
  position: absolute;
  top: 50%;
  left: ${(props) => (props.location === 'left' ? 0 : 'unset')};
  right: ${(props) => (props.location === 'right' ? 0 : 'unset')};
  z-index: 3;
  margin-top: -40px;

  @media (min-width: ${sizes.L}) {
    padding: 20px;
  }

  @media (min-width: ${sizes.XXL}) {
    left: ${(props) => (props.location === 'left' ? '-70px' : 'unset')};
    right: ${(props) => (props.location === 'right' ? '-70px' : 'unset')};
  }
`;

interface IBannerTextHolder {
  isimageIncluded: boolean;
}

export const BannerTextHolder = styled.div<IBannerTextHolder>`
  flex: 5;
  padding-top: ${(props) => (props.isimageIncluded ? '10px' : '80px')};
  padding-bottom: ${(props) => (props.isimageIncluded ? '0' : '80px')};
  padding-left: 90px;
  padding-right: 40px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  z-index: 1;

  @media (max-width: ${sizes.L}) {
    padding-left: 70px;
  }

  @media (max-width: ${sizes.M}) {
    padding: 40px;
    justify-content: flex-start;
  }
`;

export const BannerTagDateHolder = styled.div`
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
`;

export const BannerTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 6px 8px;
  color: ${colors.san_juan_grey};
  background: ${colors.zircon_light_blue};
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  margin-right: 16px;
`;

export const BannerDate = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
`;

export const BannerTitle = styled.p`
  font-weight: 600;
  font-size: 56px;
  line-height: 120%;
  margin: 0;
  white-space: pre-wrap;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  @media (max-width: ${sizes.XL}) {
    font-size: 46px;
  }
  @media (max-width: ${sizes.L}) {
    font-size: 36px;
  }
`;

export const BannerDescription = styled.p`
  font-weight: 400;
  font-size: 22px;
  line-height: 140%;
  letter-spacing: -0.01em;
  margin: 0;
  margin-top: 16px;
  white-space: pre-wrap;
  text-align: left;
  @media (max-width: ${sizes.XL}) {
    font-size: 20px;
  }
  @media (max-width: ${sizes.L}) {
    font-size: 18px;
  }
`;

export const BannerButton = styled.a`
  background: ${colors.cerulean_blue};
  border-radius: 8px;
  padding: 12px 28px 14px;
  border: 0;
  width: fit-content;
  margin-top: 24px;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  color: #ffffff;
  @media (max-width: ${sizes.XL}) {
    padding: 8px 18px 10px;
    font-size: 14px;
  }
`;

interface IBannerImageHolder {
  multipleBanners: boolean;
}

export const BannerImageHolder = styled.div<IBannerImageHolder>`
  flex: 4.5;
  margin-top: 48px;
  margin-bottom: ${(props) => (props.multipleBanners ? '48px' : '-48px')};
  margin-right: 48px;
  width: 100%;
  min-height: 300px;
  z-index: 1;

  > span {
    min-height: 300px;
    border-radius: 24px;
  }

  @media (max-width: ${sizes.L}) {
    margin-top: 28px;
    margin-bottom: ${(props) => (props.multipleBanners ? '28px' : '-28px')};
    margin-right: 28px;
  }

  @media (max-width: ${sizes.M}) {
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    width: calc(100% - 80px);
  }
  @media (max-width: ${sizes.S}){
    width: calc(100% - 40px);
    // margin-bottom: ${(props) => (props.multipleBanners ? '28px' : '-6em')};
  }
  @media (max-width: ${sizes.XS}){
    margin-bottom: ${(props) => (props.multipleBanners ? '28px' : '-28px')};
  }
`;

export const BannerImage = styled(Image)`
  // border-radius: 24px;
  // object-fit: fill!important;
  // min-height: auto!important;
  // height: auto!important;
`;

export const BannerListHolder = styled.div`
  margin-top: 33px;
  display: flex;
  flex-direction: column;

  > :not(:last-child) {
    margin-bottom: 14px;
  }

  @media (max-width: ${sizes.L}) {
    > :not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;

interface IBannerListItem {
  icon: string;
}

export const BannerListItem = styled.p<IBannerListItem>`
  font-weight: 600;
  font-size: 19px;
  line-height: 150%;
  color: ${colors.white};
  text-align: left;
  display: flex;

  &:before {
    content: '';
    display: inline-block;
    width: 28px;
    height: 28px;
    background: url(${(props) => props.icon}) no-repeat;
    margin-right: 16px;
    background-size: contain;
    background-position: center;
  }

  @media (max-width: ${sizes.L}) {
    font-size: 14px;

    &:before {
      width: 20px;
      height: 20px;
    }
  }

  @media (max-width: ${sizes.M}) {
    font-size: 12px;

    &:before {
      width: 14px;
      height: 14px;
    }
  }
`;
