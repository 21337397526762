export const colors = {
  mainBlueColor: '#164686',
  borderColor: '#D6DADF',
  linkColor: '#08aade',
  mainTextColor: '#334D70',
  headerTextColor: '#263A54'
};

export const indigo = '#263A54 ';
export const secondary = '#596E8A';
