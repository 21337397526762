import Image from 'next/image';
import React, { FC } from 'react';
import ImageGallery from 'react-image-gallery';
import { formatDate } from '../../util/helpers';
import { IMedia } from '../../util/types';
import {
  BannerBackground,
  BannerButton,
  BannerContainer,
  BannerDate,
  BannerDescription,
  BannerImage,
  BannerImageHolder,
  BannerListHolder,
  BannerListItem,
  BannerNav,
  BannerTag,
  BannerTagDateHolder,
  BannerTextHolder,
  BannerTitle,
} from './style';

interface IListItem {
  icon: IMedia;
  text: string;
}

interface IBanner {
  title: string;
  description?: string;
  backgroundImage?: IMedia;
  showcaseImage?: IMedia;
  tag?: string;
  date?: string;
  list?: IListItem[];
  button?: {
    text: string;
    url: string;
  };
}

interface MainBannerProps {
  banners: IBanner[];
}

const MainBanner: FC<MainBannerProps> = (props) => {
  const { banners } = props;

  if (!banners || banners.length === 0) {
    return null;
  }

  return (
    <BannerContainer>
      <ImageGallery
        autoPlay
        slideInterval={5000}
        items={banners as any}
        showPlayButton={false}
        showFullscreenButton={false}
        showThumbnails={false}
        showNav={banners.length > 1}
        renderLeftNav={(onClick, disabled) => (
          <BannerNav onClick={onClick} location="left" disabled={disabled}>
            <Image src="/images/banner-arrow-left.svg" width={40} height={40} alt="prev banner" />
          </BannerNav>
        )}
        renderRightNav={(onClick, disabled) => (
          <BannerNav onClick={onClick} location="right" disabled={disabled}>
            <Image src="/images/banner-arrow-right.svg" width={40} height={40} alt="next banner" />
          </BannerNav>
        )}
        renderItem={(item: any) => {
          const banner = item as IBanner;
          const isShowcaseImageIncluded = !!banner?.showcaseImage?.data;
          const isButtonIncluded = !!banner?.button;
          const isListIncluded = !!banner?.list;

          return (
            <BannerBackground
              backgroundImage={banner.backgroundImage?.data?.attributes?.url}
              multipleBanners={banners.length > 1}
            >
              <BannerTextHolder isimageIncluded={isShowcaseImageIncluded}>
                {(banner.tag || banner.date) && (
                  <BannerTagDateHolder>
                    {banner.tag && <BannerTag>{banner.tag}</BannerTag>}
                    {banner.date && <BannerDate>{formatDate(banner.date)}</BannerDate>}
                  </BannerTagDateHolder>
                )}
                <BannerTitle>{banner.title}</BannerTitle>
                {banner.description && <BannerDescription>{banner.description}</BannerDescription>}
                {isButtonIncluded && (
                  <BannerButton href={banner.button?.url}>{banner.button?.text}</BannerButton>
                )}
                {isListIncluded && Array.isArray(banner.list) && (
                  <BannerListHolder>
                    {banner.list.map((item, index) => (
                      <BannerListItem icon={item.icon?.data?.attributes?.url} key={index}>
                        {item.text}
                      </BannerListItem>
                    ))}
                  </BannerListHolder>
                )}
              </BannerTextHolder>
              {banner?.showcaseImage?.data && (
                <BannerImageHolder multipleBanners={banners.length > 1}>
                  <BannerImage
                    src={banner?.showcaseImage?.data.attributes.url}
                    width={
                      (banner?.showcaseImage?.data.attributes.width /
                        banner?.showcaseImage?.data.attributes.height) *
                      100
                    }
                    height="100%"
                    layout="responsive"
                    objectFit="cover"
                  />
                </BannerImageHolder>
              )}
            </BannerBackground>
          );
        }}
      />
    </BannerContainer>
  );
};

export default MainBanner;
