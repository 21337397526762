import sharedColors from '@pickles/shared/utils/colors';
import styled from 'styled-components';
import { colors } from '../../../styles/colors';
import sizes from '../../../util/sizes';

interface HelperProps {
  backgroundColor?: string;
  sidePadding?: string;
  mobilePadding?: string;
  mediumPadding?: string;
  isBackground?: boolean;
}

export const LayoutHelper = styled.div<HelperProps>`
  padding: ${({ sidePadding = '135px' }) => `0 ${sidePadding}`};
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin: ${({ isBackground }) => (isBackground ? '60px -135px' : 0)};
  width: 100%;
  strong {
    color: ${colors.headerTextColor};
  }

  a {
    color: ${colors.linkColor};
  }

  p {
    // margin: 24px 0;
    /* line-height: 26px; */
    color: ${sharedColors.san_juan_grey};
  }

  li {
    line-height: 26px;
    margin: 5px 0;
    color: ${sharedColors.san_juan_grey};
  }

  h2 {
    font-size: 36px;
    margin-bottom: 24px;
  }

  h3 {
    // font-size: 24px;
    // margin-bottom: 12px;
  }

  h1,
  h2,
  h3 {
    color: ${colors.headerTextColor};
  }

  @media (max-width: ${sizes.L}) {
    padding: ${({ mediumPadding }) => mediumPadding !== null && `0 ${mediumPadding}`};
  }

  @media (max-width: ${sizes.M}) {
    margin: 0;
    padding: ${({ mobilePadding = '0' }) => `0 ${mobilePadding}`};
    p {
      margin: 0;
      // padding: ${({ mobilePadding = '0' }) => `0 ${mobilePadding}`};
    }
  }
`;
