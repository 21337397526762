import React, { FC } from 'react';
import DataLoadingError from '../components/Shared/Error/DataLoadingError';
import { useSetNavigationData } from '../hooks/useSetNavigationData';

const withStrapiDataValidator = (BaseComponent: FC<any>, noDataErrorMessage?: string) => {
  const WrappedComponent = (props: any) => {
    const data = props?.data?.data?.attributes;
    const footerData = props?.footerData?.data?.attributes;
    const navigationData = props.navigationData?.data?.attributes;

    _logger.info('withStrapiDataValidator', { data, footerData, navigationData });

    useSetNavigationData(navigationData);

    if (!data) {
      return <DataLoadingError footerData={footerData} errorText={noDataErrorMessage} />;
    }

    return <BaseComponent {...props} />;
  };

  return WrappedComponent;
};

export default withStrapiDataValidator;
