import { IWeb } from '@pickles/shared/models';
import { HeaderLinksData } from '@pickles/shared/models/web.types';
import { webActions } from '@pickles/shared/redux/actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Page, Pages } from '../pages/api/navigation';

export const useSetNavigationData = (navData: Pages) => {
  const dispatch = useDispatch();

  const composeNavigationData = (data: Pages): HeaderLinksData => {
    const sellPages = data?.sell_pages.data.map(
      (link: Page): IWeb.ILink => {
        return {
          id: link.attributes.slug,
          title: link.attributes.title,
          link: link.attributes.slug,
          basePath: link.attributes.basePath,
        };
      },
    );

    const servicesPages = data?.services.data.map(
      (link: Page): IWeb.ILink => {
        return {
          id: link.attributes.slug,
          title: link.attributes.title,
          link: link.attributes.slug,
          basePath: link.attributes.basePath,
        };
      },
    );

    const aboutUsPages = data?.about_us.data.map(
      (link: Page): IWeb.ILink => {
        return {
          id: link.attributes.slug,
          title: link.attributes.title,
          link: link.attributes.slug,
          basePath: link.attributes.basePath,
        };
      },
    );

    const morePages = data?.more.data.map(
      (link: Page): IWeb.ILink => {
        return {
          id: link.attributes.slug,
          title: link.attributes.title,
          link: link.attributes.slug,
          basePath: link.attributes.basePath,
        };
      },
    );

    const eoiPages =
      data?.eois?.data.map(
        (link: Page): IWeb.ILink => {
          return {
            id: link.attributes.slug,
            title: link.attributes.title,
            link: link.attributes.slug,
            basePath: link.attributes.basePath,
          };
        },
      ) || [];

    const buyPages = eoiPages;

    const auctionPageData = data?.buy_pages?.data;

    auctionPageData?.forEach((link) => {
      buyPages.push({
        id: link.attributes.slug,
        title: link.attributes.title,
        link: link.attributes.slug,
        basePath: link.attributes.basePath,
      });
    });

    return {
      sellPages,
      servicesPages,
      aboutUsPages,
      morePages,
      buyPages,
    };
  };

  useEffect(() => {
    const data = composeNavigationData(navData);
    dispatch(webActions.setHeaderLinks(data));
  }, [dispatch, navData]);
};
