import React, { FC } from 'react';
import styled from 'styled-components';
import { Layout } from '../../PageLayout';
import { HistoryLine } from '../HistoryLine';
import { LayoutHelper } from '../Layout/styles';

interface Props {
  errorText?: string;
  footerData?: object;
}

/**
 * Check if the data being passed is available. If not shows the error message.
 * @param props.errorText optional - optional error message
 * @param props.footerData optional - if footer needs to display
 * @returns react component or null according to the data being passed
 */
const DataLoadingError: FC<Props> = ({
  errorText = 'Error occured while loading data',
  footerData,
}) => (
  <Layout footerData={footerData} title="Error">

    <HistoryLine />

    <LayoutHelper sidePadding="135px" mobilePadding="40px">
      <ErrorTextContainer>
        <ErrorTextContainerText>{errorText}</ErrorTextContainerText>
      </ErrorTextContainer>
    </LayoutHelper>

  </Layout>
);

export default DataLoadingError;

const ErrorTextContainer = styled.div`
  width: 100%;
  margin: 100px 20px;
`;

const ErrorTextContainerText = styled.p`
  font-size: 17px;
  color: #334d70;
  text-align: center;
`;
